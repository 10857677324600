<template>
    <div>
        <div class="home">
            
        </div>
        <el-form class="search_box" ref="form" :model="search" label-width="70px" size='medium' :inline="true">
            <el-form-item label="日期" label-width="100px">
                <el-date-picker v-model="search.fromTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="selectTime" clearable />
            </el-form-item>
            



            <!-- 全部 待发货  待收货  未支付  已完成  已取消 -->
            <el-form-item label-width="0">
                <el-button size='medium' type='primary' @click="serchFn">搜索</el-button>
                <el-button size='medium' type='primary' @click="emptys">重置</el-button>
            </el-form-item>
        </el-form>
        <div v-if="info.operationaldatastat && info.operationaldatastat.length>0">
            <div>运营数据概览</div>
            <div class="item-box">
                <div class="item" v-for="(item,index) in info.operationaldatastat" :key="index">
                    <div>{{item.title}}</div>
                    <div>{{item.statday}}</div>
                    <div class="price">{{item.num}}{{ item.unit }}</div>
                </div>
            </div>
        </div>
        <div v-if="info.activitydatastat && info.activitydatastat.length>0">
            <div style="margin-top: 30px;">活跃数据概览</div>
            <div class="item-box">
                <div class="item" v-for="(item,index) in info.activitydatastat" :key="index">
                    <div>{{item.title}}</div>
                    <div>{{item.statday}}</div>
                    <div class="price">{{item.num}}{{ item.unit }}</div>
                </div>
            </div>
        </div>
        
    </div>
</template>
    
<script>
import { GetStatInfos } from '@/service/dataProfiling/dataProfiling.js'
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            loadingShow: false,
            info: {},
            search: {}
        }
    },
    mounted() {
        this.GetStatInfos()
    },
    methods: {
        serchFn() {
            this.GetStatInfos()
        },
        emptys() {
            for (const key in this.search) {
                this.search[key] = undefined
            }
            this.search.page = 1
            this.search.pageSize = 10
            this.serchFn()
        },
        // 时间选择器
        selectTime(time) {
            console.log(time);
            if (time != null) {
                this.$set(this.search, "startTime", time[0]);
                this.$set(this.search, "endTime", time[1]);
            } else {
                delete this.search.startTime;
                delete this.search.endTime;
            }
        },
        GetStatInfos() {
            this.loadingShow = true
            GetStatInfos(this.search).then(res => {
                this.loadingShow = false;
                console.log(res)
                this.info = res.data
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },

    }
}
</script>
<style lang="less" scoped>
.home {
    width: 520px;
}

.item-box {
    display: flex;
    margin-top: 40px;

    .item {
        min-width: 200px;
        flex: 1;
        height: 120px;
        border-radius: 10px;
        box-shadow: 2px 3px 10px 1px #ccc;
        margin: 0 20px;
        padding: 20px 10px;
    }
}

.price {
    display: flex;
    margin-top: 20px;
}

.flex_1 {
    flex: 1;
}
</style>
    
    